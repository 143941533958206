<template>
    <main style="width: 96vw" class="px-4 pt-20 h-full">
        <div class="rounded-md border-2 mt-16">Pricings</div>
    </main>
</template>
<script>
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { mapState } from 'pinia'

export default {
    name: 'PricingsSettings',
    components: {},
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {}
    },
    validations() {
        return {}
    },
    watch: {},

    mounted() {
        window.scrollTo(0, 0)
    },

    unmounted() {},
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    methods: {},
}
</script>
