import OrdersSettings from '../views/settings/OrdersSettings.vue'
import PricingsSettings from '../views/settings/PricingsSettings.vue'
import SettingsWrapper from '../views/settings/Wrapper.vue'

const settings = [
    {
        path: '/settings',
        name: 'settings',
        // redirect: '/login',
        component: SettingsWrapper,
        // redirect: { name: 'orders_settings' },
        meta: {
            layout: 'full-layout',
            authorisations: [
                'FULL_ACCESS',
                'SETTINGS_FULL',
                'SETTINGS_ORDERSCODES',
                'SETTINGS_NOTIFICATIONS',
                'SETTINGS_PRICINGS',
            ],
            showUniverseSearch: false,
        },
        children: [
            {
                path: 'orders',
                name: 'orders_settings',
                component: OrdersSettings,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_ORDERSCODES',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'notifications',
                name: 'notifications_settings',
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_NOTIFICATIONS',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'pricings',
                name: 'pricings_settings',
                component: PricingsSettings,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_PRICINGS',
                    ],
                    showUniverseSearch: false,
                },
            },
            // {
            //     path: 'company/cgv',
            //     name: 'cgv',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'CGV_SETTINGS_FULL'],
            //     },
            // },
            // {
            //     path: 'company/vehicles-dispatch',
            //     name: 'vehicle_dispatch',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'V_SETTINGS_FULL'],
            //     },
            // },
            // {
            //     path: 'user/:email',
            //     name: 'user_params',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'ORDERS_SETTINGS_FULL'],
            //     },
            // },
        ],
    },
]

export default settings
