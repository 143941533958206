import { defineStore } from 'pinia'
import { useClientStore } from './clientStore'

const useOrderStore = defineStore('order', {
    state: () => ({
        all: null,
        count: null,
        countSteps: null,
        stepsTotalPages: null,
        currentStep: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        steps: null,
        importedOrders: null,
        importedOrdersCount: null,
        importedOrdersTotalPages: null,
        orderFileTypes: null,
        orderFiletypesCount: null,
        trackedPackages: [],
        orderToDuplicate: null,
        allClientUnpaid: null,
        countUnpaid: null,
        containersOnHold: null,
        totalPagesUnpaid: null,
        reminderSendingErrors: [],
        setAsPaidErrors: [],
        allUnpaid: null,
        countAllUnpaid: null,
        totalPagesAllUnpaid: null,
        allNotInvoiced: null,
        countNotInvoiced: null,
        totalPagesNotInvoiced: null,
    }),
    actions: {
        async searchOrders(payload) {
            // this.current = null
            this.loading = true
            this.fetchError = false
            let showRec = false

            let query = []

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            const statusFilter = []
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    if (type.dbField !== undefined) {
                        if (type.dbField !== 'IdUniverse') {
                            if (type.value === 'planned') {
                                showRec = true
                            }
                            if (type.dbField !== 'Status') {
                                if (
                                    type.dbField === 'Id' ||
                                    type.dbField === 'IdClient' ||
                                    type.dbField === 'OrderStep.IdDriver' ||
                                    type.dbField ===
                                        'OrderClientTour_OrderClient.IdOrderClientTour'
                                ) {
                                    if (
                                        type.dbField === 'OrderStep.IdDriver' &&
                                        type.value === 0
                                    ) {
                                        query.push(
                                            `{"OrderStep.IdDriver":[{"operator":"IN","value":["null", "0", ""]}]}`,
                                        )
                                    } else {
                                        query.push(
                                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                                        )
                                    }
                                    if (type.dbField === 'OrderStep.IdDriver') {
                                        query.push(
                                            `{"OrderStep.Type":[{"operator":"LIKE","value":"pickup"}]}`,
                                        )
                                    }
                                    if (
                                        type.dbField ===
                                        'OrderClientTour_OrderClient.IdOrderClientTour'
                                    ) {
                                        showRec = false
                                    }
                                } else if (
                                    type.dbField === 'OrderClient.IdUniverse'
                                ) {
                                    const universesIds = []
                                    type.value.forEach((universe) => {
                                        universesIds.push(universe.value)
                                    })
                                    if (universesIds.length > 0) {
                                        // showRec = false
                                        query.push(
                                            `{"OrderClient.IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                                        )
                                    }
                                }
                                //  else if (
                                //     type.dbField !== 'DateBegin' ||
                                //     type.dbField !== 'DateEnd'
                                // ) {
                                //     query.push(
                                //         `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                                //     )
                                // }

                                //  was condition for last else if (
                                //     type.dbField === 'OrderClient.PaymentStatus'
                                // )
                                else {
                                    query.push(
                                        `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                                    )
                                }
                            } else if (type.value !== 'planned') {
                                statusFilter.push(`"${type.value}"`)
                            }
                        }
                    }
                })
                if (statusFilter.length > 0) {
                    const plannedIndex = statusFilter.findIndex(
                        (st) => st === '"planned"',
                    )
                    if (plannedIndex !== -1) {
                        statusFilter.splice(plannedIndex, 1)
                    }
                    query.push(
                        `{"Status":[{"operator":"IN","value":[${statusFilter}]}]}`,
                    )
                }
            }
            if (
                statusFilter.length === 0 &&
                showRec === false &&
                !payload.toInvoice
            ) {
                // pas de filtre sur le status et pas de rec
                // showRec = true
                query.push(
                    `{"Status":[{"operator":"IN","value":["new", "inprogress", "finished", "imported", "canceled"]}]}`,
                )
            } else if (
                statusFilter.length === 0 &&
                showRec === true &&
                !payload.toInvoice
            ) {
                query.push(
                    `{"Status":[{"operator":"NOT IN","value":["new", "inprogress", "finished",  "imported"]}]}`,
                )
            }
            if (payload.dateFilter) {
                const endFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'DateEnd',
                )
                const startFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'DateBegin',
                )
                if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '' && start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}],"DateEnd":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    } else if (end.value === '' && start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    } else if (end.value !== '' && start.value === '') {
                        query.push(
                            `{"DateEnd":[{"operator":">=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex === -1
                ) {
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '') {
                        query.push(
                            `{"DateEnd":[{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex === -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    if (start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    }
                }
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/order/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&withRecurrents=${showRec}`
            } else {
                apiUrl = `/api/v1/order/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&withRecurrents=${showRec}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200 && res.data.Records) {
                            const orders = []
                            res.data.Records.forEach((order) => {
                                let formattedOrder = null
                                let currentDriver = null
                                let isInvoiced = false
                                order.OrderSteps.forEach((step) => {
                                    if (step.IdInvoice) {
                                        isInvoiced = true
                                    }
                                })
                                let iconInfos = {}
                                if (order.Status === 'imported') {
                                    iconInfos = {
                                        title: 'imported_order',
                                        icon: 'file-export',
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[
                                              order.OrderSteps.length - 1
                                          ].Driver
                                        : null
                                } else if (order.Status === 'planned') {
                                    iconInfos = {
                                        title: 'recurrent',
                                        icon: 'calendar',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (order.Status === 'canceled') {
                                    iconInfos = {
                                        title: 'canceled',
                                        icon: 'rectangle-xmark',
                                    }
                                    currentDriver = null
                                } else if (
                                    order.Status === 'finished' ||
                                    (order.ExecutedSteps &&
                                        +order.ExecutedSteps ===
                                            order.OrderSteps.length) ||
                                    (order.OrderSteps &&
                                        order.OrderSteps[
                                            order.OrderSteps.length - 1
                                        ].Status === 'executed')
                                ) {
                                    iconInfos = {
                                        icon: 'flag-checkered',
                                        title: 'last_step_finished',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (order.Status === 'new') {
                                    iconInfos = {
                                        title: 'delivery_new',
                                        icon: 'hourglass-half',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (
                                    order.Status === 'inprogress' &&
                                    order.ExecutedSteps &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '' &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '0'
                                ) {
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        (order.ExecutedSteps &&
                                            order.OrderSteps[
                                                +order.ExecutedSteps
                                            ].Vehicle.Type === '') ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[order.ExecutedSteps]
                                              .Driver
                                        : null
                                } else if (order.Status === 'inprogress') {
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === '' ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[order.ExecutedSteps]
                                              .Driver
                                        : null
                                } else {
                                    iconInfos = {
                                        icon: null,
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.ExecutedSteps &&
                                        order.OrderSteps[order.ExecutedSteps]
                                            .Driver
                                            ? order.OrderSteps[
                                                  order.ExecutedSteps
                                              ].Driver
                                            : null
                                }
                                // TODO mettre un icone pour les récurrentes planifiés
                                const currentSteps = order.OrderSteps
                                    ? this.setCurrentSteps(
                                          order.OrderSteps,
                                          iconInfos.icon,
                                          order.OrderCharters
                                              ? order.OrderCharters
                                              : [],
                                      )
                                    : []
                                const groupedPackages = []
                                if (order.OrderPackages) {
                                    order.OrderPackages.forEach((op, index) => {
                                        op.Quantity = 1

                                        const indexInGroupedPackages =
                                            groupedPackages.findIndex(
                                                (gp) =>
                                                    gp.IdContainer ===
                                                        op.IdContainer &&
                                                    gp.Weight === op.Weight &&
                                                    gp.Value === op.Value &&
                                                    gp.Height === op.Height &&
                                                    gp.Length === op.Length &&
                                                    gp.Width === op.Width &&
                                                    gp.TemperatureOption ===
                                                        op.TemperatureOption,
                                            )
                                        if (
                                            groupedPackages.length === 0 ||
                                            indexInGroupedPackages === -1
                                        ) {
                                            groupedPackages.push(op)
                                        } else {
                                            groupedPackages[
                                                indexInGroupedPackages
                                            ].Quantity += 1
                                        }
                                    })
                                }
                                const files = []
                                order.DeliveryOrderFiles.forEach((file) => {
                                    file.name = file.Name

                                    file.Type =
                                        +file.IdOrderFileType > 0
                                            ? 'no_sub_folder'
                                            : 'user'
                                    files.push(file)
                                })
                                if (
                                    order.TransportSplit &&
                                    order.TransportSplit.length > 0
                                ) {
                                    order.TransportSplit.forEach((slip) => {
                                        const file = structuredClone(slip)
                                        file.Name = file.FileName
                                        file.name = file.FileName

                                        file.Type = 'slip'
                                        files.push(file)
                                    })
                                }
                                if (
                                    order.OrderCharters &&
                                    order.OrderCharters.length > 0
                                ) {
                                    order.OrderCharters.forEach((charter) => {
                                        const file = structuredClone(charter)
                                        file.Name = file.FileName
                                        file.Type = 'charter'
                                        file.Id = charter.Id
                                        file.name = file.FileName
                                        files.push(file)
                                    })
                                }
                                formattedOrder = {
                                    id: +order.Id,
                                    isInvoiced,
                                    date: order.DateBegin,
                                    client_n_reference:
                                        (order.Client
                                            ? `${order.Client.Name} - `
                                            : '') +
                                        (order.OrderClient.Reference
                                            ? order.OrderClient.Reference
                                            : ''),
                                    reference: order.OrderClient
                                        ? order.OrderClient.Reference
                                        : '',
                                    weight: order.Weight,
                                    duration: order.OrderClient
                                        ? order.OrderClient.Duration
                                        : '',
                                    distance: order.OrderClient
                                        ? order.OrderClient.Distance
                                        : '',
                                    price: order.OrderClient
                                        ? order.OrderClient.Price
                                        : '',
                                    externalId: order.OrderClient
                                        ? order.OrderClient.IdExternal
                                        : '',
                                    conteneurRef:
                                        (order.OrderPackages[0] &&
                                            order.OrderPackages[0]
                                                .ReferenceExternal) ??
                                        '',
                                    subInfoForListDisplay: order.OrderClient
                                        ? order.OrderClient.IdExternal
                                        : '',
                                    subInfoText: 'external_ref',
                                    client: order.Client
                                        ? order.Client.Name
                                        : '',
                                    societyId: order.Client
                                        ? order.Client.IdSociety
                                        : '',
                                    current_driver: currentDriver,
                                    number_of_parcels: +order.PacketNumber,
                                    delivery_mode: order.Method,
                                    sales: order.Sales,
                                    onus: order.ONUs,
                                    hasHazardousMaterial: order.ONUs.length > 0,
                                    vehicle_type:
                                        order.OrderClient.VehicleType === null
                                            ? ''
                                            : order.OrderClient.VehicleType,
                                    contact: order.Contact,
                                    idOrderClient: order.IdOrderClient,
                                    executedSteps: order.ExecutedSteps,
                                    break: order.Break === 1,
                                    steps: order.OrderSteps,
                                    multiParamItemAction:
                                        order.Status === 'waitingforpayment' ||
                                        order.Status === 'planned',
                                    currentStepsToDisplay: currentSteps,
                                    packages: order.OrderPackages,
                                    groupedPackages,
                                    idClient: order.IdClient,
                                    client_comment: order.CommentClient,
                                    internal_comment: order.CommentInternal,
                                    elapsed: currentSteps[0].elapsed,
                                    onHoldSince: currentSteps[0].onHoldSince,
                                    deadline: new Date(),
                                    amount_to_bill:
                                        order.OrderClient.ForcedPrice,
                                    universe:
                                        order.OrderClient &&
                                        order.OrderClient.Universe
                                            ? order.OrderClient.Universe.Name
                                            : '',
                                    idUniverse: order.OrderClient
                                        ? order.OrderClient.IdUniverse
                                        : 0,
                                    recurrent:
                                        order.OrderClient &&
                                        order.OrderClient.Recurrent
                                            ? +order.OrderClient.Recurrent === 1
                                            : !order.Id,
                                    roundTrip:
                                        order.OrderClient &&
                                        order.OrderClient.RoundTrip
                                            ? +order.OrderClient.RoundTrip === 1
                                            : false,
                                    deliveryId: order.IdDelivery,
                                    // order_client: order.Client,
                                    worth: +order.PacketValue,
                                    status: order.Status,
                                    idRecurrentDate:
                                        order.Status === 'planned'
                                            ? order.IdRecurrentDate
                                            : null,

                                    icons: {
                                        status: {
                                            icon: iconInfos,
                                            // order.Status === 'inprogress'
                                            //     ? 'truck-fast'
                                            //     : 'flag-checkered',
                                        },

                                        delivery_mode: {
                                            icon:
                                                order.Method === 'fast'
                                                    ? 'gauge-high'
                                                    : order.Method === 'express'
                                                      ? 'rocket'
                                                      : order.Method ===
                                                          'orderGrouping'
                                                        ? 'arrows-to-circle'
                                                        : 'gauge',
                                            style:
                                                order.Method === 'fast'
                                                    ? 'color: orange; '
                                                    : order.Method === 'express'
                                                      ? 'color: red;'
                                                      : order.Method ===
                                                          'orderGrouping'
                                                        ? 'color: blue;'
                                                        : 'color: green;',
                                        },
                                    },
                                    orderGroup:
                                        order.OrderClient.OrderClientTour,
                                    creation_date: order.CreationDate,
                                    modification_date: order.ModificationDate,
                                    delivery_order_files:
                                        order.DeliveryOrderFiles &&
                                        order.DeliveryOrderFiles.length > 0
                                            ? order.DeliveryOrderFiles
                                            : [],
                                    slip: order.TransportSplit
                                        ? order.TransportSplit
                                        : null,
                                    charters: order.OrderCharters
                                        ? order.OrderCharters
                                        : [],

                                    files,
                                    email_status: order.EmailStatus,
                                }
                                orders.push(formattedOrder)
                            })

                            this.fetchError = false
                            if (payload.unpaid && payload.unpaid === true) {
                                this.allUnpaid = orders
                                this.countAllUnpaid = +res.data.TotalResults
                                this.totalPagesAllUnpaid = +res.data.TotalPages
                                this.loading = false
                            } else {
                                this.all = orders
                                this.containersOnHold = orders
                                this.count = +res.data.TotalResults
                                this.totalPages = +res.data.TotalPages
                                this.loading = false
                            }
                        } else if (payload.unpaid && payload.unpaid === true) {
                            this.allUnpaid = null
                            this.countAllUnpaid = 0
                            this.totalPagesAllUnpaid = 0
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false
                        reject(err)
                    })
            })
        },
        async searchOrdersWithContainersOnHold(payload) {
            // this.current = null
            this.loading = true
            this.fetchError = false

            let query = []

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    if (type.dbField !== undefined) {
                        if (type.dbField !== 'IdUniverse') {
                            if (type.dbField !== 'Status') {
                                if (
                                    type.dbField === 'Id' ||
                                    type.dbField === 'IdClient' ||
                                    type.dbField === 'OrderStep.IdDriver'
                                ) {
                                    if (
                                        type.dbField === 'OrderStep.IdDriver' &&
                                        type.value === 0
                                    ) {
                                        query.push(
                                            `{"OrderStep.IdDriver":[{"operator":"IN","value":["null", "0", ""]}]}`,
                                        )
                                    } else {
                                        query.push(
                                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                                        )
                                    }
                                    if (type.dbField === 'OrderStep.IdDriver') {
                                        query.push(
                                            `{"OrderStep.Type":[{"operator":"LIKE","value":"pickup"}]}`,
                                        )
                                    }
                                } else if (
                                    type.dbField === 'OrderClient.IdUniverse'
                                ) {
                                    const universesIds = []
                                    type.value.forEach((universe) => {
                                        universesIds.push(universe.value)
                                    })
                                    if (universesIds.length > 0) {
                                        // showRec = false
                                        query.push(
                                            `{"OrderClient.IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                                        )
                                    }
                                }
                                //  else if (
                                //     type.dbField !== 'DateBegin' ||
                                //     type.dbField !== 'DateEnd'
                                // ) {
                                //     query.push(
                                //         `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                                //     )
                                // }

                                //  was condition for last else if (
                                //     type.dbField === 'OrderClient.PaymentStatus'
                                // )
                                else {
                                    query.push(
                                        `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                                    )
                                }
                            }
                        }
                    }
                })
            }

            if (payload.dateFilter) {
                const endFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'DateEnd',
                )
                const startFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'DateBegin',
                )
                if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '' && start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}],"DateEnd":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    } else if (end.value === '' && start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    } else if (end.value !== '' && start.value === '') {
                        query.push(
                            `{"DateEnd":[{"operator":">=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex === -1
                ) {
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '') {
                        query.push(
                            `{"DateEnd":[{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex === -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    if (start.value !== '') {
                        query.push(
                            `{"DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    }
                }
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/order/onHold`
            } else {
                apiUrl = `/api/v1/order/onHold?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200 && res.data.Records) {
                            const orders = []
                            res.data.Records.forEach((order) => {
                                let formattedOrder = null
                                let currentDriver = null
                                let isInvoiced = false
                                order.OrderSteps.forEach((step) => {
                                    if (step.IdInvoice) {
                                        isInvoiced = true
                                    }
                                })
                                let iconInfos = {}
                                if (order.Status === 'imported') {
                                    iconInfos = {
                                        title: 'imported_order',
                                        icon: 'file-export',
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[
                                              order.OrderSteps.length - 1
                                          ].Driver
                                        : null
                                } else if (order.Status === 'planned') {
                                    iconInfos = {
                                        title: 'recurrent',
                                        icon: 'calendar',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (
                                    order.Status === 'finished' ||
                                    (order.ExecutedSteps &&
                                        +order.ExecutedSteps ===
                                            order.OrderSteps.length) ||
                                    (order.OrderSteps &&
                                        order.OrderSteps[
                                            order.OrderSteps.length - 1
                                        ].Status === 'executed')
                                ) {
                                    iconInfos = {
                                        icon: 'flag-checkered',
                                        title: 'last_step_finished',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (order.Status === 'new') {
                                    iconInfos = {
                                        title: 'delivery_new',
                                        icon: 'hourglass-half',
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.OrderSteps.length > 0
                                            ? order.OrderSteps[
                                                  order.OrderSteps.length - 1
                                              ].Driver
                                            : null
                                } else if (
                                    order.Status === 'inprogress' &&
                                    order.ExecutedSteps &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '' &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '0'
                                ) {
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.ExecutedSteps &&
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        (order.ExecutedSteps &&
                                            order.OrderSteps[
                                                +order.ExecutedSteps
                                            ].Vehicle.Type === '') ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[order.ExecutedSteps]
                                              .Driver
                                        : null
                                } else if (order.Status === 'inprogress') {
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === '' ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver = order.OrderSteps
                                        ? order.OrderSteps[order.ExecutedSteps]
                                              .Driver
                                        : null
                                } else {
                                    iconInfos = {
                                        icon: null,
                                    }
                                    currentDriver =
                                        order.OrderSteps &&
                                        order.ExecutedSteps &&
                                        order.OrderSteps[order.ExecutedSteps]
                                            .Driver
                                            ? order.OrderSteps[
                                                  order.ExecutedSteps
                                              ].Driver
                                            : null
                                }
                                // TODO mettre un icone pour les récurrentes planifiés
                                const currentSteps = order.OrderSteps
                                    ? this.setCurrentSteps(
                                          order.OrderSteps,
                                          iconInfos.icon,
                                          order.OrderCharters
                                              ? order.OrderCharters
                                              : [],
                                      )
                                    : []
                                const groupedPackages = []
                                if (order.OrderPackages) {
                                    order.OrderPackages.forEach((op, index) => {
                                        op.Quantity = 1

                                        const indexInGroupedPackages =
                                            groupedPackages.findIndex(
                                                (gp) =>
                                                    gp.IdContainer ===
                                                        op.IdContainer &&
                                                    gp.Weight === op.Weight &&
                                                    gp.Value === op.Value &&
                                                    gp.Height === op.Height &&
                                                    gp.Length === op.Length &&
                                                    gp.Width === op.Width &&
                                                    gp.TemperatureOption ===
                                                        op.TemperatureOption,
                                            )
                                        if (
                                            groupedPackages.length === 0 ||
                                            indexInGroupedPackages === -1
                                        ) {
                                            groupedPackages.push(op)
                                        } else {
                                            groupedPackages[
                                                indexInGroupedPackages
                                            ].Quantity += 1
                                        }
                                    })
                                }
                                const files = []
                                order.DeliveryOrderFiles.forEach((file) => {
                                    file.name = file.Name

                                    file.Type =
                                        +file.IdOrderFileType > 0
                                            ? 'no_sub_folder'
                                            : 'user'
                                    files.push(file)
                                })
                                if (
                                    order.TransportSplit &&
                                    order.TransportSplit.length > 0
                                ) {
                                    order.TransportSplit.forEach((slip) => {
                                        const file = structuredClone(slip)
                                        file.Name = file.FileName
                                        file.name = file.FileName

                                        file.Type = 'slip'
                                        files.push(file)
                                    })
                                }
                                if (
                                    order.OrderCharters &&
                                    order.OrderCharters.length > 0
                                ) {
                                    order.OrderCharters.forEach((charter) => {
                                        const file = structuredClone(charter)
                                        file.Name = file.FileName
                                        file.Type = 'charter'
                                        file.Id = charter.Id
                                        file.name = file.FileName
                                        files.push(file)
                                    })
                                }
                                formattedOrder = {
                                    id: +order.Id,
                                    isInvoiced,
                                    date: order.DateBegin,
                                    reference: order.OrderClient
                                        ? order.OrderClient.Reference
                                        : '',
                                    client_n_reference:
                                        (order.Client
                                            ? `${order.Client.Name} - `
                                            : '') +
                                        (order.OrderClient
                                            ? order.OrderClient.Reference
                                            : ''),
                                    weight: order.Weight,
                                    duration: order.OrderClient
                                        ? order.OrderClient.Duration
                                        : '',
                                    distance: order.OrderClient
                                        ? order.OrderClient.Distance
                                        : '',
                                    price: order.OrderClient
                                        ? order.OrderClient.Price
                                        : '',
                                    externalId: order.OrderClient
                                        ? order.OrderClient.IdExternal
                                        : '',
                                    conteneurRef:
                                        (order.OrderPackages[0] &&
                                            order.OrderPackages[0]
                                                .ReferenceExternal) ??
                                        '',
                                    subInfoForListDisplay: order.OrderClient
                                        ? order.OrderClient.IdExternal
                                        : '',
                                    subInfoText: 'external_ref',
                                    client: order.Client
                                        ? order.Client.Name
                                        : '',
                                    societyId: order.Client
                                        ? order.Client.IdSociety
                                        : '',
                                    current_driver: currentDriver,
                                    number_of_parcels: +order.PacketNumber,
                                    delivery_mode: order.Method,
                                    sales: order.Sales,
                                    onus: order.ONUs,
                                    hasHazardousMaterial: order.ONUs.length > 0,
                                    vehicle_type:
                                        order.OrderClient.VehicleType === null
                                            ? ''
                                            : order.OrderClient.VehicleType,
                                    contact: order.Contact,
                                    idOrderClient: order.IdOrderClient,
                                    executedSteps: order.ExecutedSteps,
                                    break: order.Break === 1,
                                    steps: order.OrderSteps,
                                    multiParamItemAction:
                                        order.Status === 'waitingforpayment' ||
                                        order.Status === 'planned',
                                    currentStepsToDisplay: currentSteps,
                                    packages: order.OrderPackages,
                                    groupedPackages,
                                    idClient: order.IdClient,
                                    client_comment: order.CommentClient,
                                    internal_comment: order.CommentInternal,
                                    amount_to_bill:
                                        order.OrderClient.ForcedPrice,
                                    universe:
                                        order.OrderClient &&
                                        order.OrderClient.Universe
                                            ? order.OrderClient.Universe.Name
                                            : '',
                                    idUniverse: order.OrderClient
                                        ? order.OrderClient.IdUniverse
                                        : 0,
                                    recurrent:
                                        order.OrderClient &&
                                        order.OrderClient.Recurrent
                                            ? +order.OrderClient.Recurrent === 1
                                            : !order.Id,
                                    roundTrip:
                                        order.OrderClient &&
                                        order.OrderClient.RoundTrip
                                            ? +order.OrderClient.RoundTrip === 1
                                            : false,
                                    elapsed: currentSteps[0].elapsed,
                                    onHoldSince: currentSteps[0].onHoldSince,

                                    deadline: new Date(),
                                    deliveryId: order.IdDelivery,
                                    // order_client: order.Client,
                                    worth: +order.PacketValue,
                                    status: order.Status,
                                    idRecurrentDate:
                                        order.Status === 'planned'
                                            ? order.IdRecurrentDate
                                            : null,

                                    icons: {
                                        status: {
                                            icon: iconInfos,
                                            // order.Status === 'inprogress'
                                            //     ? 'truck-fast'
                                            //     : 'flag-checkered',
                                        },

                                        delivery_mode: {
                                            icon:
                                                order.Method === 'fast'
                                                    ? 'gauge-high'
                                                    : order.Method === 'express'
                                                      ? 'rocket'
                                                      : order.Method ===
                                                          'orderGrouping'
                                                        ? 'arrows-to-circle'
                                                        : 'gauge',
                                            style:
                                                order.Method === 'fast'
                                                    ? 'color: orange; '
                                                    : order.Method === 'express'
                                                      ? 'color: red;'
                                                      : order.Method ===
                                                          'orderGrouping'
                                                        ? 'color: blue;'
                                                        : 'color: green;',
                                        },
                                    },
                                    orderGroup:
                                        order.OrderClient.OrderClientTour,
                                    creation_date: order.CreationDate,
                                    modification_date: order.ModificationDate,
                                    delivery_order_files:
                                        order.DeliveryOrderFiles &&
                                        order.DeliveryOrderFiles.length > 0
                                            ? order.DeliveryOrderFiles
                                            : [],
                                    slip: order.TransportSplit
                                        ? order.TransportSplit
                                        : null,
                                    charters: order.OrderCharters
                                        ? order.OrderCharters
                                        : [],

                                    files,
                                }
                                orders.push(formattedOrder)
                            })

                            this.fetchError = false

                            this.containersOnHold = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.containersOnHold = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false
                        reject(err)
                    })
            })
        },
        searchSteps(payload) {
            this.loading = true
            this.fetchError = false

            // const clients = JSON.parse(JSON.stringify(rootState.clients.all))

            const query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"OrderClient.IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                            )
                        }
                    } else if (type.dbField === 'Order.Status') {
                        if (type.value !== 'all') {
                            let q = ''
                            if (type.value === 'imported') {
                                q = `{"${type.dbField}":[{"operator":"IN","value":["imported"]}]}`
                            } else if (type.value === 'not_imported') {
                                q = `{"${type.dbField}":[{"operator":"NOT IN","value":["imported"]}]}`
                            }

                            query.push(q)
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }
            if (payload.toCorrect) {
                query.push(
                    `{"Latitude":[{"operator": "IS NULL", "value": ""}], "Longitude":[{"operator": "IS NULL", "value": ""}]}`,
                )
            }
            if (payload.date) {
                query.push(
                    `{"FromDateTime":[{"operator": "LIKE", "value": "${payload.date}"}]}`,
                )
                // query.push(`"Longitude":[{"operator": "IS NULL", "value": ""}]`)
            }
            if (payload.deliveryOnly) {
                query.push(
                    `{"Type":[{"operator": "LIKE", "value": "delivery"}]}`,
                )
            }
            if (payload.forSim) {
                query.push(
                    `{"Order.Status":[{"operator": "NOT LIKE", "value": "waitingforpayment"}]}`,
                )
            }
            const apiUrl = `/api/v1/orderStep/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const steps = []
                            res.data.Records.forEach(async (step) => {
                                const formattedStep = {
                                    id: step.Id,
                                    name: step.AddressName,
                                    address: `${step.Address1} ${step.Address2}`,
                                    postal_code: step.PostalCode,
                                    city: step.City,
                                    creation_date: step.CreationDate,
                                    order_status: step.OrderStatus,
                                }

                                steps.push(formattedStep)
                            })

                            this.steps = steps
                            this.countSteps = +res.data.TotalResults
                            this.stepsTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.steps = null
                            this.countSteps = 0
                            this.stepsTotalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = err
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchNotInvoicedOrders(payload) {
            this.fetchError = false

            this.loading = true

            let query = []

            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            if (payload.dateFilter) {
                const endFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'Order.DateEnd',
                )
                const startFilterExistsIndex = payload.dateFilter.findIndex(
                    (df) => df.dbField === 'Order.DateBegin',
                )
                if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '' && start.value !== '') {
                        query.push(
                            `{"Order.DateBegin":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}],"Order.DateEnd":[{"operator":">=","value":"${start.value}"},{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    } else if (end.value === '' && start.value !== '') {
                        query.push(
                            `{"Order.DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    } else if (end.value !== '' && start.value === '') {
                        query.push(
                            `{"Order.DateEnd":[{"operator":">=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex !== -1 &&
                    startFilterExistsIndex === -1
                ) {
                    const end = payload.dateFilter[endFilterExistsIndex]
                    if (end.value !== '') {
                        query.push(
                            `{"Order.DateEnd":[{"operator":"<=","value":"${end.value}"}]}`,
                        )
                    }
                } else if (
                    endFilterExistsIndex === -1 &&
                    startFilterExistsIndex !== -1
                ) {
                    const start = payload.dateFilter[startFilterExistsIndex]
                    if (start.value !== '') {
                        query.push(
                            `{"Order.DateBegin":[{"operator":">=","value":"${start.value}"}]}`,
                        )
                    }
                }
            }
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        // TODO TO REMOVE AFTER FRED's API  FIX
                        if (
                            q.dbField === 'Order.Client.Name' ||
                            q.dbField === 'OrderPackage.ReferenceExternal'
                        ) {
                            query.push(
                                `{"Order.CreationDate":[{"operator":">","value":"2017-01-01"}]}`,
                            )
                        }
                        // TODO END
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/orderClient/notInvoiced?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/orderClient/notInvoiced?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const ocs = []
                            res.data.Records.forEach((oc) => {
                                const steps = []
                                steps.push(
                                    oc.Orders[0].OrderSteps[0],
                                    oc.Orders[0].OrderSteps[
                                        oc.Orders[0].OrderSteps.length - 1
                                    ],
                                )
                                //     {
                                //         AddressName: oc.PickupAddressName,
                                //         Address1: oc.PickupAddress1,
                                //         Address2: oc.PickupAddress2,
                                //         Address3: oc.PickupAddress3,
                                //         Address4: oc.PickupAddress4,
                                //         PostalCode: oc.PickupPostalCode,
                                //         City: oc.PickupCity,
                                //         Country: oc.PickupCountry,
                                //         Moment: oc.PickupMoment,
                                //         FromDateTime: oc.PickupFromDateTime,
                                //         BeforeDateTime: oc.PickupBeforeDateTime,
                                //         Latitude: oc.PickupLatitude,
                                //         Longitude: oc.PickupLongitude,
                                //         Type: 'pickup',
                                //         Status: '',
                                //         TypeVehicle: oc.VehicleType,
                                //     },
                                //     {
                                //         AddressName: oc.DeliveryAddressName,
                                //         Address1: oc.DeliveryAddress1,
                                //         Address2: oc.DeliveryAddress2,
                                //         Address3: oc.DeliveryAddress3,
                                //         Address4: oc.DeliveryAddress4,
                                //         PostalCode: oc.DeliveryPostalCode,
                                //         City: oc.DeliveryCity,
                                //         Country: oc.DeliveryCountry,
                                //         Moment: oc.DeliveryMoment,
                                //         FromDateTime: oc.DeliveryFromDateTime,
                                //         BeforeDateTime:
                                //             oc.DeliveryBeforeDateTime,
                                //         Latitude: oc.DeliveryLatitude,
                                //         Longitude: oc.DeliveryLongitude,
                                //         Type: 'delivery',
                                //         Status: '',
                                //         TypeVehicle: oc.VehicleType,

                                //     },
                                // )

                                const currentSteps = steps
                                    ? this.setCurrentSteps(
                                          steps,
                                          '',
                                          oc.Orders[0].OrderCharters
                                              ? oc.Orders[0].OrderCharters
                                              : [],
                                      )
                                    : []

                                const groupedPackages = []
                                oc.OrderPackages.forEach((op, index) => {
                                    op.Quantity = 1

                                    const indexInGroupedPackages =
                                        groupedPackages.findIndex(
                                            (gp) =>
                                                gp.IdContainer ===
                                                    op.IdContainer &&
                                                gp.Weight === op.Weight &&
                                                gp.Value === op.Value &&
                                                gp.Height === op.Height &&
                                                gp.Length === op.Length &&
                                                gp.Width === op.Width &&
                                                gp.TemperatureOption ===
                                                    op.TemperatureOption,
                                        )
                                    if (
                                        groupedPackages.length === 0 ||
                                        indexInGroupedPackages === -1
                                    ) {
                                        groupedPackages.push(op)
                                    } else {
                                        groupedPackages[
                                            indexInGroupedPackages
                                        ].Quantity += 1
                                    }
                                })
                                const files = []
                                oc.Orders[0].DeliveryOrderFiles.forEach(
                                    (file) => {
                                        file.name = file.Name
                                        file.Type =
                                            +file.IdOrderFileType > 0
                                                ? 'no_sub_folder'
                                                : 'user'

                                        files.push(file)
                                    },
                                )
                                if (oc.Orders[0].TransportSplit) {
                                    const file = structuredClone(
                                        oc.Orders[0].TransportSplit,
                                    )
                                    file.Name = file.FileName
                                    file.Type = 'slip'
                                    file.name = file.FileName

                                    files.push(file)
                                }
                                if (
                                    oc.Orders[0].OrderCharters &&
                                    oc.Orders[0].OrderCharters.length > 0
                                ) {
                                    oc.Orders[0].OrderCharters.forEach(
                                        (charter) => {
                                            const file =
                                                structuredClone(charter)
                                            file.Name = file.FileName
                                            file.Type = 'charter'
                                            file.Id = charter.Id
                                            file.name = file.FileName
                                            files.push(file)
                                        },
                                    )
                                    oc.Orders[0].OrderSteps.forEach((step) => {
                                        const orderCharter =
                                            oc.Orders[0].OrderCharters.find(
                                                (c) =>
                                                    c.IdOrderStepEnd ===
                                                    step.Id,
                                            )

                                        if (orderCharter) {
                                            step.PurchasePrice =
                                                orderCharter.PurchasePrice
                                            step.SellingPrice =
                                                orderCharter.SellingPrice
                                            step.Margin = orderCharter.Margin
                                            step.SendCharter = true
                                        }
                                    })
                                }
                                const formattedOc = {
                                    id: oc.Id,
                                    universeId: oc.IdUniverse,
                                    currentStepsToDisplay: currentSteps,
                                    client: oc.Orders[0].Client
                                        ? oc.Orders[0].Client.Name
                                        : 'NO CLIENT',
                                    client_n_reference:
                                        (oc.Orders[0].Client
                                            ? `${oc.Orders[0].Client.Name} - `
                                            : 'NO CLIENT - ') + oc.Reference,

                                    // drivers: oc.Drivers,
                                    // vehicle: oc.Vehicles,
                                    subInfoForListDisplay: oc.IdExternal,
                                    price: oc.Price,
                                    groupedPackages,
                                    subInfoText: 'external_ref',
                                    packages: oc.OrderPackages.length
                                        ? oc.OrderPackages
                                        : null,
                                    reference: oc.Reference,
                                    amount_to_bill: oc.ForcedPrice, // oc.Amount,
                                    // FORMATER
                                    conteneurRef:
                                        (oc.OrderPackages[0] &&
                                            oc.OrderPackages[0]
                                                .ReferenceExternal) ??
                                        '',
                                    orderId: oc.Orders[0].Id,
                                    distance: oc.Distance ? oc.Distance : null,
                                    duration: oc.Duration ? oc.Duration : null,
                                    sales: oc.Orders[0].Sales,
                                    steps: oc.Orders[0].OrderSteps,
                                    creation_date: oc.CreationDate,
                                    modification_date: oc.ModificationDate,
                                    delivery_order_files:
                                        oc.Orders[0].DeliveryOrderFiles &&
                                        oc.Orders[0].DeliveryOrderFiles.length >
                                            0
                                            ? oc.Orders[0].DeliveryOrderFiles
                                            : [],
                                    slip: oc.Orders[0].TransportSplit
                                        ? oc.Orders[0].TransportSplit
                                        : null,
                                    charters: oc.Orders[0].OrderCharters
                                        ? oc.Orders[0].OrderCharters
                                        : [],

                                    files,
                                }
                                ocs.push(formattedOc)
                            })
                            this.allNotInvoiced = ocs
                            this.countNotInvoiced = +res.data.TotalResults
                            this.totalPagesNotInvoiced = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.allNotInvoiced = null
                            this.countNotInvoiced = 0
                            this.totalPagesNotInvoiced = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        setCurrentSteps(steps, icon = null, OrderCharters = []) {
            let formattedSteps = steps
            if (OrderCharters.length > 0) {
                formattedSteps.forEach((step) => {
                    const orderCharter = OrderCharters.find(
                        (c) => c.IdOrderStepEnd === step.Id,
                    )
                    if (orderCharter) {
                        step.PurchasePrice = orderCharter.PurchasePrice
                        step.SellingPrice = orderCharter.SellingPrice
                        step.Margin = orderCharter.Margin
                    }
                })
            }
            // console.log(steps)

            if (formattedSteps.length > 2) {
                const currentSteps = []
                steps.forEach((step, index) => {
                    if (
                        steps[steps.length - 2].Status === 'executed' ||
                        steps[steps.length - 1].Status === 'executed'
                    ) {
                        //

                        currentSteps.push(steps[steps.length - 2])
                        currentSteps.push(steps[steps.length - 1])
                    } else if (
                        currentSteps.length === 0 &&
                        step.Type === 'pickup' &&
                        (index + 1) % 2 &&
                        steps[index + 1].Status !== 'executed' &&
                        steps[index + 1].ExecutedDateTime === null
                    ) {
                        if (index !== 0) {
                            const now = new Date()
                            const previousStep = steps[index - 1]
                            const executedDateTime = new Date(
                                previousStep.ExecutedDateTime,
                            )
                            const elapsedTime =
                                Math.abs(now - executedDateTime) / 1000 // Difference in seconds
                            const elapsedDays = Math.floor(elapsedTime / 86400)
                            const elapsedHours = Math.floor(
                                (elapsedTime % 86400) / 3600,
                            )
                            const elapsedMinutes = Math.floor(
                                (elapsedTime % 3600) / 60,
                            )
                            step.elapsed = `${elapsedDays} ${this.i18n.global.t('d')} ${elapsedHours} h ${elapsedMinutes} m`
                            step.onHoldSince = new Date(
                                executedDateTime,
                            ).toLocaleTimeString(this.i18n.locale, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            })
                        }

                        if (
                            icon === null ||
                            (icon !== 'hourglass-half' &&
                                icon !== 'flag-checkered')
                        ) {
                            let vehicleIcon = ''
                            if (
                                step.IdVehicle &&
                                step.IdVehicle !== '' &&
                                step.IdVehicle !== '0'
                            ) {
                                if (step.Vehicle.Type === 'truck') {
                                    vehicleIcon = {
                                        title: 'truck',
                                        icon: 'truck',
                                    }
                                }
                                if (step.Vehicle.Type === 'bike') {
                                    vehicleIcon = {
                                        title: 'bike',
                                        icon: 'person-biking',
                                    }
                                }
                                if (step.Vehicle.Type === 'car') {
                                    vehicleIcon = {
                                        title: 'car',
                                        icon: 'car',
                                    }
                                }
                                if (step.Vehicle.Type === 'van') {
                                    vehicleIcon = {
                                        icon: 'van',
                                        title: 'van',
                                    }
                                }
                                if (step.Vehicle.Type === 'boat') {
                                    vehicleIcon = {
                                        title: 'boat',
                                        icon: 'ship',
                                    }
                                }
                                if (step.Vehicle.Type === 'deliveryTricycle') {
                                    vehicleIcon = {
                                        icon: 'deliveryTricycle',
                                        title: 'delivery_tricycle',
                                    }
                                }
                                if (step.Vehicle.Type === 'cargoBike') {
                                    vehicleIcon = {
                                        icon: 'cargoBike',
                                        title: 'cargo_bike',
                                    }
                                }
                                if (
                                    step.Vehicle.Type === '' ||
                                    step.Vehicle.Type === null
                                ) {
                                    vehicleIcon = {
                                        icon: null,
                                    }
                                }
                            }

                            step.icon = vehicleIcon
                        }
                        currentSteps.push(step)
                        currentSteps.push(steps[index + 1])
                    }
                })
                formattedSteps = currentSteps
            }
            return formattedSteps
        },
        searchByClient(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/${payload.clientId}/orders?format=pagination&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/${payload.clientId}/orders?format=pagination&query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const orders = []
                            res.data.Records.forEach(async (order) => {
                                const formattedOrder = {
                                    id: +order.Id,
                                    reference: order.Reference,
                                    weight: order.Weight,
                                    number_of_parcels: +order.PacketNumber,
                                    delivery_mode: order.Method,
                                    vehicle_type:
                                        order.OrderClient.VehicleType === null
                                            ? ''
                                            : order.OrderClient.VehicleType,

                                    steps: order.OrderSteps,
                                    packages: order.OrderPackages,
                                    worth: +order.PacketValue,
                                    status: order.Status,
                                    creation_date: order.CreationDate,
                                    duration: order.OrderClient.Duration,
                                    distance: order.OrderClient.Distance,
                                }

                                orders.push(formattedOrder)
                            })
                            this.all = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },

        searchDeliveriesByClient(payload) {
            this.loading = true
            this.current = null
            let query = []
            this.fetchError = false

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }
            if (payload.myCart === true) {
                query.push(
                    `{"OrderClient.PaymentStatus":[{"operator":"=","value":"waiting"}]}`,
                )
            }
            // query.push(
            //     `{"IdClient":[{"operator":"LIKE","value":"${payload.clientId}"}]}`
            // )

            let apiUrl = ''
            // if (Object.keys(query).length === 0) {
            //     query = ''
            //     apiUrl = `/api/v1/delivery/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            // } else {
            //     apiUrl = `/api/v1/delivery/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            // }

            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/${payload.clientId}/deliveries?format=pagination&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/${payload.clientId}/deliveries?format=pagination&query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            if (payload.myCart === true) {
                apiUrl += '&unpaidOnly=true'
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const orders = []
                            res.data.Records.forEach(async (order) => {
                                let status = ''
                                order.OrdersClient.forEach((oc) => {
                                    if (
                                        oc.PaymentStatus === 'waiting' &&
                                        status === ''
                                    ) {
                                        status = 'waiting'
                                    } else {
                                        status = 'paid'
                                    }
                                    // if (status !== 'waiting') {
                                    //     oc.Orders.forEach((o) => {
                                    //         if (
                                    //             o.Status === 'waitingforpayment'
                                    //         ) {
                                    //             status = 'waiting'
                                    //         }
                                    //     })
                                    // }

                                    oc.groupedPackages = []
                                    oc.OrderPackages.forEach((op, index) => {
                                        op.Quantity = 1

                                        const indexInGroupedPackages =
                                            oc.groupedPackages.findIndex(
                                                (gp) =>
                                                    gp.IdContainer ===
                                                        op.IdContainer &&
                                                    gp.Weight === op.Weight &&
                                                    gp.Value === op.Value &&
                                                    gp.Height === op.Height &&
                                                    gp.Length === op.Length &&
                                                    gp.Width === op.Width &&
                                                    gp.TemperatureOption ===
                                                        op.TemperatureOption,
                                            )
                                        if (
                                            oc.groupedPackages.length === 0 ||
                                            indexInGroupedPackages === -1
                                        ) {
                                            oc.groupedPackages.push(op)
                                        } else {
                                            oc.groupedPackages[
                                                indexInGroupedPackages
                                            ].Quantity += 1
                                        }
                                    })
                                })
                                const formattedOrder = {
                                    id: +order.Id,
                                    reference: order.Reference,
                                    orderClient: order.OrdersClient,

                                    name: order.Name
                                        ? order.Name
                                        : this.i18n.global.t(
                                              'no_delivery_name',
                                          ),
                                    subInfoForListDisplay: `${this.i18n.global.t(
                                        'distance_nokm',
                                    )} ${order.Distance} km 
                                    ${this.i18n.global.t('duration')} ${
                                        order.Duration
                                    } `,

                                    weight: order.Weight,
                                    duration: order.Duration,
                                    distance: order.Distance,
                                    status,
                                    // steps: order.OrderSteps,
                                    // packages: order.OrderPackages,
                                    // worth: +order.PacketValue,
                                    // status: order.Status,
                                    creation_date: order.CreationDate,
                                }

                                orders.push(formattedOrder)
                            })
                            this.all = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchUnpaidOrdersByClient(payload) {
            // this.current = null
            const query = []
            this.fetchError = false

            query.push(
                `{"Status":[{"operator":"=","value":"waitingforpayment"}]}`,
            )

            query.push(
                `{"IdClient":[{"operator":"=","value":"${payload.clientId}"}]}`,
            )

            const apiUrl = `/api/v1/order/search?query={"Query":[${query}]}&nbPP=20&page=1&order=ASC&sort=CreationDate`

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const orders = []
                            res.data.Records.forEach(async (order) => {
                                const formattedOrder = {
                                    id: +order.Id,
                                    reference: order.Reference,
                                    orderClient: [order.OrderClient],
                                    name: order.Name
                                        ? order.Name
                                        : this.i18n.global.t(
                                              'no_delivery_name',
                                          ),
                                    subInfoForListDisplay: `${this.i18n.global.t(
                                        'distance_nokm',
                                    )} ${order.Distance} km 
                                    ${this.i18n.global.t('duration')} ${
                                        order.Duration
                                    } `,

                                    weight: order.Weight,
                                    duration: order.Duration,
                                    distance: order.Distance,
                                    // steps: order.OrderSteps,
                                    // packages: order.OrderPackages,
                                    // worth: +order.PacketValue,
                                    // status: order.Status,
                                    creation_date: order.CreationDate,
                                    email_status: order.EmailStatus,
                                }

                                orders.push(formattedOrder)
                            })
                            this.allClientUnpaid = orders
                            this.countUnpaid = +res.data.TotalResults
                            this.totalPagesUnpaid = +res.data.TotalPages
                        } else {
                            this.allClientUnpaid = null
                            this.countUnpaid = 0
                            this.totalPagesUnpaid = 0
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchImportedOrders(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else if (type.dbField === 'Client.IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"Client.IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }
            // we do not want to display the files imported by user for storage
            query.push('{"IdOrderFileType":[{"operator":">","value":"0"}]}')
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/orderFile/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/orderFile/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const orders = []
                            if (count > 0) {
                                res.data.Records.forEach(async (order) => {
                                    const type = this.orderFileTypes.find(
                                        (orderFileType) =>
                                            +orderFileType.Id ===
                                            +order.IdOrderFileType,
                                    )
                                    const formattedOrder = {
                                        id: order.Id,
                                        title: `${this.i18n.global.t(
                                            'order_file_title',
                                        )} ${order.Client.Name}`,
                                        file: order.Name,
                                        path: order.Path,
                                        file_type: type
                                            ? type.Name.toUpperCase()
                                            : '',
                                        client_id: order.IdClient,
                                        status: order.Status,
                                        lines_to_import: order.LinesToImport,
                                        imported_lines: order.ImportedLines,
                                        report: order.Report.includes('_')
                                            ? this.i18n.global.t(order.Report)
                                            : order.Report,
                                        creation_date: order.CreationDate,
                                    }

                                    orders.push(formattedOrder)
                                })
                            }
                            this.importedOrders = orders
                            this.importedOrdersCount = +res.data.TotalResults
                            this.importedOrdersTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.importedOrders = null
                            this.importedOrdersCount = 0
                            this.importedOrdersTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchOrderFileTypes(payload) {
            const query = []
            this.fetchError = false

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            const apiUrl = `/api/v1/orderFileType/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults

                            this.orderFileTypes = res.data.Records
                            this.orderFiletypesCount = count
                        } else {
                            this.orderFileTypes = null
                            this.orderFiletypesCount = 0
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneDelivery(payload) {
            this.cookies.set('currentDeliveryId', payload)

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/delivery/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const order = res.data
                        // const groupedPackages = []
                        order.OrdersClient.forEach((oc) => {
                            oc.groupedPackages = []
                            oc.OrderPackages.forEach((op, index) => {
                                op.Quantity = 1
                                op.SameOpIndexes = []
                                op.packageTypeErrors = []
                                op.packageValueErrors = []
                                op.packageWeightErrors = []
                                op.packageReferenceErrors = []
                                op.packageWidthErrors = []
                                op.packageLengthErrors = []
                                op.packageHeightErrors = []
                                op.containerNameError = false
                                op.packageQuantityErrors = []

                                const indexInGroupedPackages =
                                    oc.groupedPackages.findIndex(
                                        (gp) =>
                                            gp.IdContainer === op.IdContainer &&
                                            gp.Weight === op.Weight &&
                                            gp.Value === op.Value &&
                                            gp.Height === op.Height &&
                                            gp.Length === op.Length &&
                                            gp.Width === op.Width &&
                                            gp.TemperatureOption ===
                                                op.TemperatureOption,
                                    )
                                if (
                                    oc.groupedPackages.length === 0 ||
                                    indexInGroupedPackages === -1
                                ) {
                                    oc.groupedPackages.push(op)
                                } else {
                                    oc.groupedPackages[
                                        indexInGroupedPackages
                                    ].Quantity += 1
                                    oc.groupedPackages[
                                        indexInGroupedPackages
                                    ].SameOpIndexes.push(index)
                                }
                            })
                        })

                        // todo le passe passe pour les paquets reels et les combinés
                        const current = {
                            id: +order.Id,
                            client_id: +order.IdClient,
                            orderClient: order.OrdersClient,
                            reference: order.Reference,
                            name: order.Name,
                            // groupedPackages,
                            client: order.Client,
                            contact: order.Contact,

                            checkout: order.CheckoutSession
                                ? order.CheckoutSession
                                : null,
                            creation_date: order.CreationDate,
                            modification_date: order.ModificationDate,
                            creation_user_id: +order.CreationUserId,
                            modification_user_id: +order.ModificationUserId,
                        }

                        this.current = current
                        const clientStore = useClientStore()

                        if (this.cookies.get('is_B2C') === 'false') {
                            clientStore
                                .fetchClientContacts({
                                    clientId: order.IdClient,
                                    fromOrders: true,
                                })
                                .then(resolve(res))
                        } else {
                            clientStore
                                .fetchContainers(order.IdClient)
                                .then(() => {
                                    resolve(res)
                                })
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            this.current = null

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/order/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const order = res.data
                        let isInvoiced = false
                        order.OrderSteps.forEach((step) => {
                            if (step.IdInvoice) {
                                isInvoiced = true
                            }
                        })
                        const groupedPackages = []
                        order.OrderPackages.forEach((op, index) => {
                            op.Quantity = 1
                            op.SameOpIndexes = []
                            op.packageTypeErrors = []
                            op.packageValueErrors = []
                            op.packageWeightErrors = []
                            op.packageReferenceErrors = []
                            op.packageWidthErrors = []
                            op.packageLengthErrors = []
                            op.packageHeightErrors = []
                            op.containerNameError = false
                            op.packageQuantityErrors = []

                            const indexInGroupedPackages =
                                groupedPackages.findIndex(
                                    (gp) =>
                                        gp.IdContainer === op.IdContainer &&
                                        gp.Weight === op.Weight &&
                                        gp.Value === op.Value &&
                                        gp.Height === op.Height &&
                                        gp.Length === op.Length &&
                                        gp.Width === op.Width &&
                                        gp.TemperatureOption ===
                                            op.TemperatureOption,
                                )
                            if (
                                groupedPackages.length === 0 ||
                                indexInGroupedPackages === -1
                            ) {
                                groupedPackages.push(op)
                            } else {
                                groupedPackages[
                                    indexInGroupedPackages
                                ].Quantity += 1
                                groupedPackages[
                                    indexInGroupedPackages
                                ].SameOpIndexes.push(index)
                            }
                        })
                        const files = []
                        order.DeliveryOrderFiles.forEach((file) => {
                            file.name = file.Name
                            file.Type =
                                +file.IdOrderFileType > 0
                                    ? 'no_sub_folder'
                                    : 'user'

                            files.push(file)
                        })
                        if (order.TransportSplit) {
                            const file = structuredClone(order.TransportSplit)
                            file.Name = file.FileName
                            file.Type = 'slip'
                            file.name = file.FileName

                            files.push(file)
                        }
                        if (
                            order.OrderCharters &&
                            order.OrderCharters.length > 0
                        ) {
                            order.OrderCharters.forEach((charter) => {
                                const file = structuredClone(charter)
                                file.Name = file.FileName
                                file.Type = 'charter'
                                file.Id = charter.Id
                                file.name = file.FileName
                                files.push(file)
                            })
                            order.OrderSteps.forEach((step) => {
                                const orderCharter = order.OrderCharters.find(
                                    (c) => c.IdOrderStepEnd === step.Id,
                                )

                                if (orderCharter) {
                                    step.PurchasePrice =
                                        orderCharter.PurchasePrice
                                    step.SellingPrice =
                                        orderCharter.SellingPrice
                                    step.Margin = orderCharter.Margin
                                    step.SendCharter = true
                                }
                            })
                        }
                        const current = {
                            isInvoiced,
                            begin_date: order.DateBegin,
                            end_date: order.DateEnd,
                            reception_date: order.DateReception,
                            duration: order.Duration,
                            comment_client: order.CommentClient,
                            id: +order.Id,
                            client_id: +order.IdClient,
                            driver_id: order.IdDriver,
                            id_external: order.OrderClient.IdExternal,

                            vehicle_id: order.IdVehicle,
                            invoice_id: order.IdInvoice,
                            comment_internal: order.CommentInternal,
                            order_packages: groupedPackages,
                            real_order_packages: order.OrderPackages,
                            order_steps: order.OrderSteps,
                            reference: order.OrderClient.Reference,
                            roundTrip: +order.OrderClient.RoundTrip === 1,
                            recurrent: +order.OrderClient.Recurrent === 1,
                            paymentStatus: order.OrderClient.PaymentStatus,
                            payment_type: order.OrderClient.PaymentType,
                            universe: order.OrderClient.Universe,
                            idUniverse: +order.OrderClient.IdUniverse,
                            method: order.Method,
                            vehicle_type:
                                order.OrderClient.VehicleType === null
                                    ? ''
                                    : order.OrderClient.VehicleType,

                            type: order.OrderClient.OrderClientType,
                            status: order.Status,
                            user_contact_id: order.IdUserContact,
                            creation_date: order.CreationDate,
                            modification_date: order.ModificationDate,
                            creation_user_id: +order.CreationUserId,
                            modification_user_id: +order.ModificationUserId,
                            order_options: order.OrderOptions,
                            packet_number: order.PacketNumber,
                            packet_value: order.PacketValue,
                            weight: order.Weight,
                            contact: order.Contact,
                            idDelivery: order.IdDelivery,
                            idOrderClient: order.IdOrderClient,
                            checkoutSession: order.CheckoutSession ?? null,
                            voucher_number: order.VoucherNumber,
                            client: order.Client,
                            sales: order.Sales,
                            onus: order.ONUs,
                            hasHazardousMaterial: order.ONUs.length > 0,

                            orderGroup: order.OrderClient.OrderClientTour,
                            id_pricing: order.OrderClient.IdPricing ?? null,
                            forced_price: order.OrderClient.ForcedPrice
                                ? +order.OrderClient.ForcedPrice
                                : 0,
                            price: order.OrderClient.Price
                                ? +order.OrderClient.Price
                                : 0,
                            delivery_order_files:
                                order.DeliveryOrderFiles &&
                                order.DeliveryOrderFiles.length > 0
                                    ? order.DeliveryOrderFiles
                                    : [],
                            slip: order.TransportSplit
                                ? order.TransportSplit
                                : null,
                            charters: order.OrderCharters
                                ? order.OrderCharters
                                : [],
                            files,
                            with_vat: +order.OrderClient.WithVAT,
                        }
                        this.current = current
                        const clientStore = useClientStore()

                        clientStore.$patch({
                            current: order.Client,
                            currentContacts: null,
                        })

                        clientStore.fetchContainers(order.IdClient).then(() => {
                            resolve(res)
                        })
                        clientStore.fetchClientContacts({
                            clientId: order.IdClient,
                            fromOrders: true,
                        })
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchOneOrderAndUpdateStatus(payload) {
            return new Promise((resolve, reject) => {
                this.fetchOne(payload.id)
                    .then((res) => {
                        const order = res.data
                        if (order.Status !== payload.newStatus) {
                            order.Status = payload.newStatus
                            order.ModificationUserId = payload.userId

                            const updatePayload = { id: order.Id, form: order }
                            this.updateOrder(updatePayload).then(() => {
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        trackOnePackage(pacakgeRef, steps) {
            this.trackedPackages = []
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/orderPackage/route?reference=${pacakgeRef}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const packageInfos = {
                                steps,

                                coordinates: res.data.GeoJSON.coordinates,
                            }
                            this.trackedPackages.push(packageInfos)
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        async createDelivery(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/delivery', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        async createOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/order', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        async createOrderFileType(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderFileType', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        async createOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderclient', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        async uploadOrderFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderfile', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        uploadUserFiles(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/deliveryOrderFile', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteDeliveryUserFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/deliveryOrderFile/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        cancelOrderFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/orderFile/${payload}/cancel`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        updateOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/order/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        cancelOrders(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/order/cancel`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateDeliveryName(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/delivery/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderclient/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (payload.duplicate === true) {
                            const order = res.data.Orders[0].Id
                            this.fetchOne(order).then((res) => {
                                this.orderToDuplicate = structuredClone(
                                    this.current,
                                )
                                this.current = null

                                // TODO ajouter filtre univers
                                const clientStore = useClientStore()
                                const clientsPayload = {
                                    query: [],
                                    filter: [],
                                    perPage: 10000,
                                    page: 1,
                                    sortField: 'Name',
                                    sortDirection: 'ASC',
                                }
                                clientStore.search(clientsPayload)
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        savePackageToOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/orderPackage`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updatePackageToOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderPackage/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/orderStep/${payload.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const step = res.data
                        const current = {
                            id: step.Id,
                            name: step.AddressName,
                            address: step.Address1,
                            address2: step.Address2,
                            address3: step.Address3,
                            address4: step.Address4,
                            postal_code: step.PostalCode,
                            city: step.City,
                            driver: step.IdDriver,
                            order_id: +step.IdOrder,
                            vehicle_id: +step.IdVehicle,
                            moment: step.Moment,
                            fromDateTime: step.FromDateTime,
                            beforeDateTime: step.BeforeDateTime,
                            step_number: +step.StepNumber,
                            lat: step.Latitude,
                            lng: step.Longitude,
                            creation_date: step.CreationDate,
                            creation_user_id: step.CreationUserId,
                            modification_user_id: step.ModificationuserId,
                            vehicle_type: step.TypeVehicle,
                            address_type: step.Type,
                            client_address_id: step.IdClientAddress,
                            reference_address: step.ClientAddress ?? null,
                            order: step.Order,
                        }

                        if (
                            step.Latitude === null ||
                            step.Longitude === null ||
                            step.Latitude === 0 ||
                            step.Longitude === 0 ||
                            step.Latitude === '' ||
                            step.Longitude === '' ||
                            step.Latitude === undefined ||
                            step.Longitude === undefined
                        ) {
                            this.axios
                                .get(
                                    `https://maps.googleapis.com/maps/api/geocode/json?address=${step.City},${step.PostalCode}&region=fr&key=${payload.key}`,
                                    {
                                        signal: this.controller.signal,
                                    },
                                )
                                .then((googleRes) => {
                                    if (googleRes.data.results.length > 0) {
                                        current.coordinates_by_city = {
                                            pos: {
                                                lat: JSON.parse(
                                                    JSON.stringify(
                                                        googleRes.data
                                                            .results[0].geometry
                                                            .location.lat,
                                                    ),
                                                ),

                                                lng: JSON.parse(
                                                    JSON.stringify(
                                                        googleRes.data
                                                            .results[0].geometry
                                                            .location.lng,
                                                    ),
                                                ),
                                            },
                                            label: '?',
                                            draggable: true,
                                            title: 'order_step',
                                        }
                                        this.currentStep = current
                                        resolve(res)
                                    } else {
                                        let zip = step.PostalCode.slice(0, -3)
                                        zip *= 1000
                                        this.axios
                                            .get(
                                                `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&region=fr&key=${payload.key}`,
                                                {
                                                    signal: this.controller
                                                        .signal,
                                                },
                                            )
                                            .then((googleRes2) => {
                                                if (
                                                    googleRes2.data.results
                                                        .length > 0
                                                ) {
                                                    current.coordinates_by_city =
                                                        {
                                                            pos: {
                                                                lat: JSON.parse(
                                                                    JSON.stringify(
                                                                        googleRes2
                                                                            .data
                                                                            .results[0]
                                                                            .geometry
                                                                            .location
                                                                            .lat,
                                                                    ),
                                                                ),

                                                                lng: JSON.parse(
                                                                    JSON.stringify(
                                                                        googleRes2
                                                                            .data
                                                                            .results[0]
                                                                            .geometry
                                                                            .location
                                                                            .lng,
                                                                    ),
                                                                ),
                                                            },
                                                            label: '?',
                                                            draggable: true,
                                                            title: 'order_step',
                                                        }
                                                    this.currentStep = current

                                                    resolve(res)
                                                } else {
                                                    step.Latitude = 45.71098477961418
                                                    step.Longitude = 4.731940199397795
                                                    current.coordinates_by_city =
                                                        {
                                                            pos: {
                                                                lat: 45.71098477961418,
                                                                lng: 4.731940199397795,
                                                            },
                                                            label: '?',
                                                            draggable: true,
                                                            title: 'order_step',
                                                        }
                                                    this.currentStep = current

                                                    resolve(res)
                                                }
                                            })
                                    }
                                })
                        } else {
                            this.currentStep = current

                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/order/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteManyOrders(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/orderClient/deleteRecords`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteManyDeliveries(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/delivery/deleteRecords`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderclient/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteDelivery(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/delivery/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteImportedOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderFile/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateOrderFileType(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderFileType/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        patchOrderFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(`/api/v1/orderFile/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        getOrderFileTypeFields() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get('/api/v1/orderFileField/search?format=array', {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.orderFileTypeFields = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderStep/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderStep/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentStep = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deletePackage(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderPackage/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        sendReminder(payload) {
            // array of objects [{user: email, order: id}}]
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/order/sendPaymentReminder', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        console.log(res)
                        const resArray = res.data.split('\n')
                        if (JSON.parse(resArray[1]).errors.length > 0) {
                            JSON.parse(resArray[1]).errors.forEach((error) => {
                                this.reminderSendingErrors.push(error)
                            })
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log('Error sending reminder mail to users')

                        reject(err)
                    })
            })
        },
        updateDeliveryPrice(payload) {
            // array of objects [{user: email, order: id}}]
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/delivery/price', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        setAsPaid(payload) {
            // array of objects order status modifUId id
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/order/setAsPaid', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        if (
                            err.response.data.errors &&
                            err.response.data.errors.length > 0
                        ) {
                            err.response.data.errors.forEach((error) => {
                                this.setAsPaidErrors.push(error)
                            })
                        }
                        if (err.response.data.httpCode === 400) {
                            err.response.data.entity.forEach((entity) => {
                                const formattedError = {
                                    order_ref: entity.Reference,
                                    id: entity.Id,
                                    client: entity.Client,
                                    msg: err.response.data.message,
                                }
                                this.setAsPaidErrors.push(formattedError)
                            })
                        }

                        reject(err)
                    })
            })
        },
        generateInvoice(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderClient/generateInvoice', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchOrderGroups(payload) {
            let apiUrl = '/api/v1/orderClientTour/search?nbPP=1000000'
            if (payload !== null) {
                const query = [
                    `{"DateTour":[{"operator":"LIKE","value":"${payload}"}]}`,
                ]
                apiUrl += `&query={"Query":[${query}]}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`${apiUrl}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneOrderGroup(payload) {
            this.current = null

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/orderClientTour/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateOrderGroup(payload) {
            this.current = null
            if (payload) {
                return new Promise((resolve, reject) => {
                    this.axios
                        .put(`/api/v1/orderClientTour/${payload.Id}`, payload, {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        })
                        .then((res) => {
                            resolve(res)
                        })
                        .catch((err) => {
                            console.log(err)

                            reject(err)
                        })
                })
            }
            return true
        },
        moveForManyOrders(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/orderStep/assignDriverForMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateMultiStepsStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/order/finishManyOrders`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})
export { useOrderStore }
