<template>
    <div class="container mx-auto px-4 py-4">
        <!-- <div class="grid grid-cols-3 gap-4"> -->
        <div v-for="(image, index) in images" :key="index">
            <div class="grid grid-cols-12 gap-4 mb-4">
                <div
                    class="col-span-6 cursor-zoom-in hover:z-100"
                    @click="selectImage(image)"
                >
                    <!--   -->
                    <img
                        :src="image.URL"
                        :alt="`Gallery image ${index + 1}`"
                        class="w-full rounded-lg max-h-full object-scale-down"
                    />
                    <!--TO ZOOM ON HOVER ADD THIS TO IMG duration-500 hover:scale-200 transition transform-->
                    <div
                        v-if="image.Information !== ''"
                        class="p-2 text-base font-semibold"
                    >
                        {{ image.Information }}
                    </div>
                </div>
                <div
                    class="col-span-6 rounded-lg"
                    :id="`map-${index}`"
                    style="width: auto; height: auto"
                ></div>
            </div>
        </div>

        <div
            v-if="selectedImage"
            class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-200 overflow-auto"
            @click="closeModal"
        >
            <div class="h-screen" @click="closeModal">
                <div class="p-2 text-2xl font-semibold text-white">
                    {{ selectedImage.Information }}
                </div>
                <img
                    :src="selectedImage.URL"
                    alt="Enlarged view"
                    class="max-w-full max-h-full rounded-lg"
                />
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'

export default {
    data() {
        return {
            selectedImage: null,
        }
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    mixins: [gmapStyles, googleScriptLoader],
    created() {
        this.multiMaps = true
        this.maps = []
    },
    mounted() {
        const googleInterval = setInterval(() => {
            if (google) {
                clearInterval(googleInterval)
                this.images.forEach((image, index) => {
                    const latitude = image.Latitude
                    const longitude = image.Longitude
                    const mapId = `map-${index}`
                    this.maps[mapId] = new google.maps.Map(
                        document.getElementById(mapId),
                        {
                            center: {
                                lat: parseFloat(latitude),
                                lng: parseFloat(longitude),
                            },
                            zoom: 15,
                        },
                    )
                    const marker = new google.maps.Marker({
                        animation: google.maps.Animation.DROP,
                        position: {
                            lat: parseFloat(latitude),
                            lng: parseFloat(longitude),
                        },
                        title: image.Information,
                        draggable: false,
                    })
                    marker.setMap(this.maps[mapId])
                })

                this.setGoogleMapStyle(false)
            }
        }, 20)
    },
    methods: {
        closeModal() {
            this.selectedImage = null
        },
        selectImage(image) {
            this.selectedImage = image
        },
    },
}
</script>
