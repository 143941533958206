<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'harbors'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="inspectionsList"
                :items="inspections"
                :columns="columns"
                :storeToSort="'inspections'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="false"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @deactivate="deactivateOne($event)"
                @deactivateAll="deactivateAll($event)"
                @activate="activateOne($event)"
                @openGallerydefenses="openGallery($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="showGallery"
            :showSubmit="false"
            :mxHeight="' h-screen overflow-visible'"
            :cancelText="'close'"
            :closeMark="true"
            :mxWidth="'w-4/5'"
            id="galleryModal"
            :buttonAlign="'justify-center'"
            @closeModal="openGallery()"
            ><template #body><picture-gallery :images="images" /> </template
        ></modal>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import PictureGallery from '@/components/elements/PictureGallery.vue'
import Modal from '@/components/atoms/Modal.vue'

import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useHarborStore } from '@/stores/harborStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapStores, mapWritableState } from 'pinia'
import googleScriptLoader from '@/mixins/googleScript'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Inspections',
    mixins: [filterList, googleScriptLoader],
    components: {
        FiltrableTable,
        FetchDataError,
        PictureGallery,
        Modal,
    },
    data() {
        return {
            images: [],
            showGallery: false,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            harborsInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        docks() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        this.loadGoogleScript()

        const filteredList = this.$cookies.get('filteredInspections')
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                // isLink: checkAccess(
                //     this.userStore,
                //     this.inspectionsActionsAccess.update,
                // ),
            },
            {
                name: 'description',
                filtrable: false,
                dbField: 'Description',
                sortable: false,
            },
            {
                name: 'harbor',
                filtrable: true,
                dbField: 'Harbor.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'dock',
                filtrable: true,
                dbField: 'Dock.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'defenses',
                filtrable: false,
                sortable: false,
                enum: true,
                canRemoveEnum: false,
                enumClickAction: 'openGallery',
                enumClickIcon: 'images',
                //  checkAccess(
                //     this.userStore,
                //     this.inspectionsActionsAccess.update,
                // ),
                enumColNumber: 'grid-cols-2',
            },
            {
                name: 'status',
                filtrable: true,
                dbField: 'Status',
                type: 'select',
                selected: [],
                canBeCleared: true,

                canDeselect: true,

                sortable: false,
                options: [
                    {
                        name: 'finished',
                        value: 'finished',
                        dbField: 'Status',
                    },
                    {
                        name: 'inProgress',
                        value: 'inProgress',
                        dbField: 'Status',
                    },
                ],
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },
    
    unmounted() {
        clearInterval(this.harborsInterval)
    },
    computed: {
        inspectionsActionsAccess() {
            return actionsAccess.harbors.inspections
        },
        ...mapWritableState(useHarborStore, {
            inspections: 'inspections',
            count: 'inspectionsCount',
            fetchError: 'fetchError',
            totalPages: 'inspectionsTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            // if (
            //     checkAccess(
            //         this.userStore,
            //         this.inspectionsActionsAccess.update,
            //     )
            // ) {
            //     actions.push({
            //         name: 'edit',
            //         action: 'update',
            //         icon: 'pen',
            //     })
            // }

            return actions
        },
        multiActions() {
            return []
        },
    },
    methods: {
        ...mapActions(useHarborStore, [
            'searchInspections',
            'fetchOne',
            'update',
        ]),
        openGallery(defense = null) {

            if (defense) {
                this.images = defense.Pictures
            } else {
                this.images = []
            }
            this.showGallery = !this.showGallery
        },
        upFilter(event) {
            clearInterval(this.harborsInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.inspectionsActionsAccess.create,
            )
        },
        goToUpdateOne(id) {
            clearInterval(this.harborsInterval)

            this.$emit('goToUpdateArea', id)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredInspections')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredInspections')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchInspections(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredInspections', search)
            this.harborsInterval = setInterval(() => {
                this.searchInspections(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredInspections')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
