<template>
    <div class="mr-20 bg-white darky">
        <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        />
        <component
            :is="componentToDisplay"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
// import Settings from '@/views/settings/List.vue'
import NavTabs from '@/components/elements/NavTabs.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useUserStore } from '@/stores/userStore'
import OrdersSettings from '@/views/settings/OrdersSettings.vue'
import { mapStores } from 'pinia'
import PricingsSettings from './PricingsSettings.vue'

export default {
    name: 'SettingsWrapper',
    components: {
        // Settings,
        OrdersSettings,
        PricingsSettings,
        NavTabs,
    },
    data() {
        return {
            componentToDisplay: this.humanize('orders_settings'),
            tabs: [],
        }
    },
    computed: {
        ...mapStores(useUserStore),
        settingsActionsAccess() {
            return actionsAccess.settings
        },
    },

    mounted() {
        this.$cookies.remove('filtered')
        this.tabs = []
        if (checkAccess(this.userStore, this.settingsActionsAccess.orders)) {
            this.tabs.push({
                icon: 'cart-shopping',
                name: 'orders_settings',
                disabled: false,
            })
        }
        if (checkAccess(this.userStore, this.settingsActionsAccess.pricings)) {
            this.tabs.push({
                icon: 'file-invoice-dollar',
                name: 'pricings_settings',
                disabled: false,
            })
        }
        if (
            checkAccess(
                this.userStore,
                this.settingsActionsAccess.notifications,
            )
        ) {
            this.tabs.push({
                icon: 'bell',
                name: 'notifications_settings',
                disabled: true,
            })
        }
        if (this.$route.name === 'settings') {
            this.changeContent('orders_settings')
        } else {
            this.changeContent(this.$route.name)
        }
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        changeContent(tab) {
            this.$router.push({ name: tab })
            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
